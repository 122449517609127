<template>
  <v-container tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-inbox"
          title="Caixa de Entrada"
          inline
          class="px-5 py-4 ml-0"
        >
          <v-row >
            <v-col class="pb-0 pt-1" cols="6">
             <select-company 
              ref="refSelectCompany" 
              :changeCompany="false"
              :clearable="true"
              @selected="handleCompanySelected"
              @companySelected="changeSelectedCompany($event)" 
            />
            </v-col>
                                <v-col  cols="6">
             <input-month 
                label="Competência de*" 
                hide-details 
                class="mb-0 pt-5"
                @monthSelected="setDataDe"
                :selectedMonth="competencia" />
            </v-col>
          </v-row>

          <v-tabs
            v-model="tab"
            class="mt-5"
            center-active
            grow
          >
            <v-tab>
              <v-badge
                :value="notificacoesPendentes.length > 0"
                color="warning"
                :content="notificacoesPendentes.length"
                right
              >
                Pendentes
              </v-badge>
            </v-tab>
            <v-tab>
              Concluídas
            </v-tab>
            <v-tab>
              Vencidas
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-data-table 
                  dense
                  :headers="notificacoesPendentesHeader" 
                  :items="notificacoesPendentes" 
                  :options="{ itemsPerPage: -1 }"
                  :sort-by="['created_at']"
                  :sort-desc="[true]" 
                  :headerProps="{ sortByText: 'Ordenar Por' }"
                  no-data-text="Nenhum registro disponível" 
                  no-results-text="Nenhum registro correspondente encontrado"
                  :mobile-breakpoint="10"
                >
                  <template v-slot:[`item.notification`]="{ item }">
                    <notifications-template 
                      :tipo_pendencia="item.tipo_pendencia"
                      :notificationData="item"
                    />
                  </template>
                  <template v-slot:[`item.deadline`]="{ item }">
                    {{ item.deadline | parseToDateBR }}
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ item.created_at | parseToDateTimeBR }}
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-data-table 
                  dense
                  :headers="notificacoesConcluidasHeader" 
                  :items="notificacoesConcluidas" 
                  :options="{ itemsPerPage: -1 }" 
                  :sort-by="['updated_at']"
                  :sort-desc="[true]" 
                  :headerProps="{ sortByText: 'Ordenar Por' }"
                  no-data-text="Nenhum registro disponível" 
                  no-results-text="Nenhum registro correspondente encontrado"
                  :mobile-breakpoint="10"
                >
                  <template v-slot:[`item.notification`]="{ item }">
                    <notifications-template 
                      :tipo_pendencia="item.tipo_pendencia"
                      :notificationData="item"
                    />
                  </template>
                  <template v-slot:[`item.user`]="{ item }">
                    {{ item.user != null ? item.user.name : 'Administrador' }}
                  </template>
                  <template v-slot:[`item.deadline`]="{ item }">
                    {{ item.deadline | parseToDateBR }}
                  </template>
                  <template v-slot:[`item.updated_at`]="{ item }">
                    {{ item.updated_at | parseToDateTimeBR }}
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-data-table 
                  dense
                  :headers="notificacoesVencidasHeader" 
                  :items="notificacoesVencidas" 
                  :options="{ itemsPerPage: -1 }"
                  :sort-by="['created_at']"
                  :sort-desc="[true]" 
                  :headerProps="{ sortByText: 'Ordenar Por' }"
                  no-data-text="Nenhum registro disponível" 
                  no-results-text="Nenhum registro correspondente encontrado"
                  :mobile-breakpoint="10"
                >
                  <template v-slot:[`item.notification`]="{ item }">
                    <notifications-template 
                      :tipo_pendencia="item.tipo_pendencia"
                      :notificationData="item"
                    />
                  </template>
                  <template v-slot:[`item.deadline`]="{ item }">
                    {{ item.deadline | parseToDateBR }}
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ item.created_at | parseToDateTimeBR }}
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col>
    </v-row>
    
    <v-overlay :value="loading" :opacity="0.85">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </v-overlay>
  </v-container>  
</template>

<script>

import { mapState, mapActions } from 'vuex';
import CaixaDeEntradaService from "@/services/CaixaDeEntradaService.js";

export default {
  name: "IndexCaixaDeEntrada",
  components: {
    NotificationsTemplate: () => import("@/components/caixaDeEntrada/NotificationsTemplate.vue"),
    SelectCompany: () => import('@/components/general/SelectCompany'),
    InputMonth: () => import('@/components/general/InputMonth.vue'),

  },
  data: () => ({
    tab: 0,
    competencia:null,
    empresaSelecionada: null,
    notificacoesPendentesHeader: [
      {
        text: "Empresa",
        value: "company.name"
      },
      {
        text: "Notificação",
        value: "notification",
        sortable: false,
      },
      {
        text: "Prazo",
        value: "deadline"
      },
      {
        text: "Notificado em",
        value: "created_at",
      }
    ],
    notificacoesConcluidasHeader: [
      {
        text: "Empresa",
        value: "company.name"
      },
      {
        text: "Notificação",
        value: "notification",
        sortable: false,
      },
      {
        text: "Prazo",
        value: "deadline"
      },
      {
        text: "Finalizado por",
        value: "user"
      },
      {
        text: "Finalizado em",
        value: "updated_at",
      }
    ],
    notificacoesVencidasHeader: [
      {
        text: "Empresa",
        value: "company.name"
      },
      {
        text: "Notificação",
        value: "notification",
        sortable: false,
      },
      {
        text: "Prazo",
        value: "deadline"
      },
      {
        text: "Notificado em",
        value: "created_at",
      }
    ],
    pendencias: [],
    loading: false,
  }),
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    },
    ...mapState("caixaDeEntrada", [
      "count_pendencias_not_done"
    ]),
    notificacoes() {
      let notificacoes = this.pendencias;

      if (this.empresaSelecionada != null) {
        notificacoes = notificacoes.filter(
          (pendencia) => pendencia.company_id == this.empresaSelecionada
        );
      }
      if (this.competencia != null) {
        notificacoes = notificacoes.filter((pendencia) => {
          if (pendencia.tipo_pendencia === "PendenciaImportacaoDados") {
            return pendencia.data_month == `${this.competencia}-01`;
          }
          return (
            pendencia.indger_version.data_month == `${this.competencia}-01`
          );
        });
      }
      return notificacoes;
    },
    notificacoesPendentes() {
      return this.notificacoes.filter(
        (notificacao) =>
          notificacao.done === false && notificacao.expired === false
      );
    },
    notificacoesConcluidas() {
      return this.notificacoes.filter(notificacao => notificacao.done === true);
    },
    notificacoesVencidas() {
      return this.notificacoes.filter(
        (notificacao) =>
          notificacao.done === false && notificacao.expired === true
      );
    },
  },
  mounted() {
    this.getCountUserPendenciasNotDone();
    this.getUserPendencias();
  },
  methods: {
    ...mapActions("caixaDeEntrada", [
      "setCountPendenciasNotDone"
    ]),
    getCountUserPendenciasNotDone() {
      CaixaDeEntradaService.getCountUserPendenciasNotDone()
        .then(res => {
          this.setCountPendenciasNotDone(res);
        })
        .catch(err => {
          console.error(err);
        });
    },
    getUserPendencias() {
      this.loading = true;

      CaixaDeEntradaService.getUserPendencias()
        .then(res => {
          this.pendencias = res;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => this.loading = false);
    },
    handleCompanySelected(companyId) {
      this.empresaSelecionada = companyId
    },
    setDataDe(data) {
      this.competencia = data;
    },
  }, 
}

</script>

<style>

</style>